import { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

export default function IdleTimerContainer() {
    const navigate = useNavigate();

    const onIdle = () => {
        navigate(ROUTES.HOME);
    };

    useIdleTimer({
        crossTab: true,
        timeout: 60 * 1000,
        onIdle: onIdle,
    });

    return <div className="idle-timer-container"></div>;
}