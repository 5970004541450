import { createPortal } from "react-dom";
import LayoutModal from "components/LayoutModal";
import Button from "components/Button";

const QrModal = ({ active, setActive }: { active: boolean; setActive: (a: boolean) => void }) => {
    active && document.body.classList.add("lock");

    return createPortal(
        <LayoutModal
            className="px-[94px] pt-[69px] pb-[101px] qr-code"
            closeModal={() => {
                setActive(false);
            }}
            active={active}
        >
            <div className="flex flex-col gap-[58px]">
                <p className="text-[#000] text-[32px] font-bold text-center">
                    Зарегистрируйтесь на платформе, отсканировав QR код, зарабатывайте баллы и получайте призы.
                </p>

                <img className="qr-img" src="/images/qr.png" alt="" />

                <Button
                    text="ЗАКРЫТЬ"
                    className="bg-[#8D1BFF] qr-button"
                    clickHandler={() => {
                        setActive(false);
                        document.body.classList.remove("lock");
                    }}
                />
            </div>
        </LayoutModal>,
        document.getElementById("portal") as HTMLElement,
    );
};

export default QrModal;